.container {
  min-width: 88rem;
  padding: 2rem 1.5rem;
}

@media screen and (min-width: 100rem) {
  .container {
    width: 97rem;
    padding: 2rem 0 5rem;
    margin: 0 auto;
  }
}

.list {
  margin-top: 1.5rem;
}

.error {
  height: calc(100vh - 15rem);
}
