.container {
  margin: 2rem auto;
  width: 50rem;
  padding: 1.5rem;
}

.title {
  margin-top: 0.5rem;
}

.iconAndForm {
  display: flex;
  margin-bottom: 2.25rem;
  background: inherit;
}

.icon {
  padding: 0.875rem;
  margin-right: 1.5rem;
  border-radius: 100%;
  background: linear-gradient(180deg, #78efd0 0%, #00bb99 100%);
}

.icon svg {
  fill: var(--ICON_INVERSE);
  display: block;
}

.form {
  width: 100%;
  background: inherit;
}

.form > * {
  margin-right: 5.25rem;
}

.form > *:not(:last-child) {
  margin-bottom: 1.5rem;
}

.form .saveBtn {
  width: 10.5rem;
}

.formError {
  width: 100%;
  display: flex;
  align-items: center;
}

.formError svg {
  margin-right: 1rem;
}

.tokensTitle {
  margin-top: 0.5rem;
}

.addTokensTitle {
  margin-top: 0.5rem;
}

.changeTokenInput {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
}

.changeTokenInput > div {
  width: 100%;
}
