.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorImage {
  margin-bottom: 2rem;
}

.action {
  margin-top: 2rem;
}
