.error {
  display: flex;
  align-items: center;
}

.error svg {
  margin-right: .75rem;
}

.container {
  display: flex;
  align-items: center;
}

.block {
  display: flex;
  margin-right: 1.5rem;
}

.vertical .block {
  margin-right: 2.5rem;
}

:not(.vertical) .block span:first-child {
  margin-right: .5rem;
}

.vertical .block span:first-child {
  margin-bottom: .125rem;
}

.block span:last-child {
  color: var(--TXT_SECONDARY);
}

.vertical .block {
  flex-direction: column;
}

.pad {
  flex: 1;
}

.vertical .status {
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
  transform: translateY(-50%);
}
