.container {
  width: 17.75rem;
  margin: 0 1.5rem;
  padding: 1rem 0;
}

.container.vertical {
  display: flex;
  width: auto;
  align-items: center;
  border-bottom: 1px solid var(--BORDER_SECONDARY);
}

.iconAndTitle {
  display: flex;
  align-items: center;
}

.icon {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  background: var(--SECONDARY_OPACITY_30);
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.envs {
  width: 14.25rem;
  margin-top: 1.5rem;
}

.container.vertical .envs {
  margin-top: 0;
  margin-left: 1rem;
}
