.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: calc(100% - 4em);
  text-align: center;
}

.image.dark {
  display: none;
}

:global(.theme-dark) .image.dark {
  display: block;
}

:global(.theme-dark) .image.light {
  display: none;
}
