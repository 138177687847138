.error {
  display: flex;
  align-items: center;
}

.error svg {
  margin-right: .75rem;
}

.container {
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
}

.block {
  display: flex;
  flex-direction: column;
  margin-right: 1.25rem;
}

.block span:first-child {
  margin-bottom: .125rem;
  font-size: 1.25rem;
}

.block span:last-child {
  font-size: .75rem;
}

.latency {
  color: var(--BLOCK8);
}

.serverErrors {
  color: var(--BLOCK1);
}

.clientErrors {
  color: var(--BLOCK2);
}

.success {
  color: var(--SUCCESS);
}

.rps {
  color: var(--BLOCK9);
}
