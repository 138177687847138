.container {
  width: 12rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: var(--BG_OBJECT);
  border-radius: 0 0.375rem 0.375rem 0;
}

.container.vertical {
  width: auto;
  height: 4rem;
  flex-direction: row;
  border-radius: 0 0 0.375rem 0.375rem;
}

.container.vertical > a {
  flex: 1;
}

.container.vertical > a:not(:last-child) {
  margin-right: 0.5rem;
}

.error {
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.error h4 {
  margin: 0;
}

.container.vertical.error {
  flex-direction: row;
  justify-content: center;
}

.container.vertical.error svg {
  margin-right: 1rem;
}

.channelLinkButton {
  width: 100%;
}
.channelLinkDisabled {
  pointer-events: none;
}

.link {
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
}
