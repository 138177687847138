.container {
  height: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.userIcon {
  margin-right: 0.5rem;
  fill: var(--ICON_PRIMARY);
  transition: fill .3s;
}

:global(.theme-dark) .userIcon {
  fill: var(--ICON_SECONDARY);
}

.container:hover .userIcon {
  fill: var(--PRIMARY_HOVER);
}

.userName {
  font-size: 0.875rem;
  transition: color .3s;
}

:global(.theme-dark) .userName {
  color: var(--TXT_SECONDARY);
}

.container:hover .userName {
  color: var(--PRIMARY_HOVER);
}

.errorIcon {
  margin-right: 0.5rem;
}

.errorText {
  font-weight: 600;
}

.menu {
  position: absolute;
  background: var(--BG_PLATE);
  box-shadow: var(--TOOLTIP_SHADOW);
  transform: translate(-.5rem, 4.25rem);
}

.container.opened .userIcon {
  fill: var(--PRIMARY_ACTIVE);
}

.container.opened .userName {
  color: var(--PRIMARY_ACTIVE);
}

.menu .item {
  font-size: 0.875rem;
  padding: .5rem 1rem;
  transition: color .3s;
  display: block;
  color: var(--TXT_PRIMARY);
  text-decoration: none;
}

.menu .item:hover {
  color: var(--PRIMARY_HOVER);
}

.menu .item.active {
  color: var(--PRIMARY_ACTIVE);
  cursor: default;
}
