.controlPanel {
  display: flex;
  align-items: center;
  height: 7rem;
  padding: 0 1.5rem;
}

.text {
  height: 100%;
  display: flex;
  align-items: baseline;
  line-height: 8.125rem;
  user-select: none;
}

.env {
  margin-left: 1.375rem;
}

.period {
  margin-left: 0.875rem;
}

.pad {
  flex: 1;
}

.controlPanel .btn {
  height: auto;
  border-color: var(--BORDER_PRIMARY);
  color: var(--BORDER_PRIMARY);
  display: block;
}

.btn svg {
  display: block;
}

.btn.activeBtn {
  border-color: var(--PRIMARY_ACTIVE);
  color: var(--PRIMARY_ACTIVE);
}

.gridBtn {
  margin-left: 0.5rem;
}

.search {
  margin-top: -.625rem;
  margin-left: 1.5rem;
  width: 16rem;
}

.sortBy {
  margin-top: -.625rem;
  margin-left: 1.5rem;
  width: 16rem;
}
