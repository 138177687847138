.container {
  display: grid;
  gap: 1.5rem;
}

.grid {
  grid-template-columns: 1fr 1fr;
}

.list {
  grid-template-columns: 1fr;
}
