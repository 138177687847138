@import-normalize;

@import '@platformeco/ui-components/dist/main.css';
@import '@platformeco/ui-components/dist/css/index';
@import '@platformeco/ui-components/dist/css/fonts';

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --TXT_SIZE: 16px;
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: var(--TXT_SIZE);
  background: var(--BG_PAGE);
  color: var(--TXT_PRIMARY);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--BORDER_SECONDARY);
}

::-webkit-scrollbar-thumb {
  background: var(--BORDER_PRIMARY);
}
