.container {
  border-radius: .625rem;
  font-size: .625rem;
  width: 4.625rem;
  height: 0.8125rem;
  text-transform: uppercase;
  text-align: center;
}

.degraded {
  background: var(--WARNING_OPACITY_30);
  color: var(--BLOCK2);
  border: 1px solid var(--BLOCK2);
}

.ok {
  background: var(--SUCCESS_OPACITY_30);
  color: var(--SUCCESS);
  border: 1px solid var(--SUCCESS);
}

.down {
  background: var(--DANGER_OPACITY_30);
  color: var(--ERROR);
  border: 1px solid var(--ERROR);
}
