.header {
  padding: 0 1.5rem;
  overflow: visible !important;
}

.logo {
  height: 2rem;
  margin-left: -.23625rem;
}

.logo img {
  height: 100%;
}

.pad {
  flex: 1;
}

.themeToggler {
  margin-left: 1rem;
}
