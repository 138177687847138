.container {
  margin: 2rem auto;
  width: 50rem;
  padding: 1.5rem;
}

.title {
  margin-top: 0.5rem;
}

.iconAndForm {
  display: flex;
  margin-bottom: 2.25rem;
  background: inherit;
}

.icon {
  padding: 0.875rem;
  margin-right: 1.5rem;
  border-radius: 100%;
  background: linear-gradient(180deg, #78efd0 0%, #00bb99 100%);
}

.icon svg {
  fill: var(--ICON_INVERSE);
  display: block;
}

.form {
  width: 100%;
  background: inherit;
}

.form > * {
  margin-right: 5.25rem;
}

.form > *:not(:last-child) {
  margin-bottom: 1.5rem;
}

.form .saveBtn {
  width: 10.5rem;
}
.formError {
  width: 100%;
  display: flex;
  align-items: center;
}

.formError svg {
  margin-right: 1rem;
}

.tokenListItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.tokenListItemContent {
  margin-left: 10px;
}

.tokenIcon {
  margin: 10px;
  justify-self: flex-end;
  /* margin-top: 15px; */
}
.tokenListItemLabel {
  padding: 3px 6px;
  margin-right: 5px;
  font-weight: 600;
}

.tokensList {
  width: 100%;
}

.modal {
  height: fit-content;
  max-width: 40rem;

  left: 50% !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.modalDelete {
  height: fit-content;
  max-width: 25rem;

  left: 50% !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.addTokenForm {
  padding: 3rem;
}
.addTokenInput {
  margin-top: 1rem;
}
.addTokenButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.addTokenButton {
  min-width: 7rem;
  margin-top: 1rem;
}
.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
}
