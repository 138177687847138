.card {
  height: 10rem;
  display: flex;
  position: relative;
}

.card.vertical {
  flex-direction: column;
  height: 18.75rem;
}

.error {
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
}

.errorIcon {
  margin-right: 1rem;
}

.middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;
}

.card:not(.vertical) .middle {
  margin: 0 1.5rem 0 0;
}

.middle > * {
  height: 50%;
}

.middle > :first-child {
  border-bottom: 1px solid var(--BORDER_SECONDARY);
}
